import { Accordion } from '@components/accordion';
import { Button } from 'layout/Button';
import { type FC, type HTMLAttributes, useEffect, useState } from 'react';
import { PossibleRootCauseItemContainer } from './styled';
import NewsImg from '@assets/images/news.svg';
import { IoMdThumbsDown, IoMdThumbsUp } from 'react-icons/io';
import { FiThumbsUp } from 'react-icons/fi';
import { LuThumbsDown } from 'react-icons/lu';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { type PossibleRootCause } from 'interfaces/change/RootCause.interface';
import {
  useFetchPossibleRootCauses,
  useSendRootCausesFeedback,
} from 'api/changes/changesServices';

enum FeedbackEnum {
  POSITIVE = 'true',
  NEGATIVE = 'false',
}

interface PossibleRootCauseProps {
  changeId?: string;
  handleAssign: (value?: number) => void;
}

const PossibleRootCauseComponent: FC<PossibleRootCauseProps> = ({
  changeId,
  handleAssign,
}) => {
  const { theme } = useTheme();
  const [activeRootCause, setActiveRootCause] = useState<
    PossibleRootCause | undefined
  >();
  const [feedback, setFeedback] = useState<FeedbackEnum | undefined>();
  const { data } = useFetchPossibleRootCauses(changeId);
  const { mutate } = useSendRootCausesFeedback();

  const handleFeedback = (value: FeedbackEnum): void => {
    if (feedback === value) {
      setFeedback(undefined);
      return;
    }
    setFeedback(value);
  };

  const matchCurrentFeedback = (value: FeedbackEnum): boolean => {
    return value === feedback;
  };

  useEffect(() => {
    const rootCauses = data?.possible_root_causes;
    if (rootCauses && rootCauses?.length > 0) {
      setActiveRootCause(rootCauses[0]);
    }
  }, [data]);

  useEffect(() => {
    if (feedback !== undefined) {
      mutate(Boolean(JSON.parse(feedback)));
    }
  }, [feedback]);

  const handleOnClickAssign = (rootCauseId: number): void => {
    handleAssign(rootCauseId);
  };

  return (
    <div>
      {data?.possible_root_causes && data.possible_root_causes.length > 0 && (
        <Accordion
          title="EnerVision Insight -  Possible Root Cause"
          defaultOpen={true}
          variant="gray"
          addorment={{
            startAddorment: <img src={NewsImg} height={16} width={16} />,
          }}
        >
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center gap-4">
              {data?.possible_root_causes?.map((option) => (
                <PossibleRootCauseItem
                  key={option.id}
                  title={option.name}
                  percentage={option.likelihood_percentage}
                  active={activeRootCause?.id === option.id}
                  onClick={() => {
                    setActiveRootCause(option);
                  }}
                />
              ))}
            </div>
            <div>{activeRootCause?.description}</div>
            <div className="flex flex-col gap-4">
              <h5 className="font-bold">How to validate</h5>
              <p>{activeRootCause?.instructions}</p>
            </div>
            <div className="flex flex-col gap-4">
              <h5 className="font-bold">How to solve</h5>
              <p>{activeRootCause?.solution}</p>
            </div>
            {activeRootCause?.source && (
              <div>
                <p className="italic">{`Source: ${activeRootCause?.source}`}</p>
              </div>
            )}
            <div className="flex justify-between items-end">
              <div className="flex flex-col">
                <h5 className="font-bold">Was this helpful?</h5>
                <div className="flex gap-4 pt-3">
                  <div
                    className={`p-2 cursor-pointer rounded-full hover:bg-primary-lighter-2 ${
                      matchCurrentFeedback(FeedbackEnum.NEGATIVE)
                        ? 'bg-primary-lighter-2'
                        : ''
                    }`}
                    onClick={() => {
                      handleFeedback(FeedbackEnum.NEGATIVE);
                    }}
                  >
                    {matchCurrentFeedback(FeedbackEnum.NEGATIVE) ? (
                      <IoMdThumbsDown size={24} color={theme.colors.primary} />
                    ) : (
                      <LuThumbsDown size={24} color={theme.colors.primary} />
                    )}
                  </div>
                  <div
                    className={`p-2 cursor-pointer rounded-full hover:bg-primary-lighter-2 ${
                      matchCurrentFeedback(FeedbackEnum.POSITIVE)
                        ? 'bg-primary-lighter-2'
                        : ''
                    }`}
                    onClick={() => {
                      handleFeedback(FeedbackEnum.POSITIVE);
                    }}
                  >
                    {matchCurrentFeedback(FeedbackEnum.POSITIVE) ? (
                      <IoMdThumbsUp size={24} color={theme.colors.primary} />
                    ) : (
                      <FiThumbsUp size={24} color={theme.colors.primary} />
                    )}
                  </div>
                </div>
              </div>
              <Button
                label="Assign as main cause"
                onClick={() => {
                  if (activeRootCause?.root_cause_id) {
                    handleOnClickAssign(activeRootCause.root_cause_id);
                  }
                }}
              />
            </div>
          </div>
        </Accordion>
      )}
    </div>
  );
};

interface PossibleRootCauseItemProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  percentage: number;
  active: boolean;
}

const PossibleRootCauseItem: FC<PossibleRootCauseItemProps> = ({
  title,
  percentage,
  active,
  ...props
}) => {
  return (
    <PossibleRootCauseItemContainer
      $active={active}
      data-enervision-item
      {...props}
    >
      <p className="text-base font-bold">{percentage}%</p>
      <p className="text-sm">{title}</p>
    </PossibleRootCauseItemContainer>
  );
};
export default PossibleRootCauseComponent;
