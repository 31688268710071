/* eslint-disable react/no-unescaped-entities */
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition,
} from '@headlessui/react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import {
  type FC,
  type PropsWithChildren,
  type ReactNode,
  useMemo,
} from 'react';
import Tippy from '@tippyjs/react';

interface AccordionProps extends PropsWithChildren {
  title: string;
  items?: { id: string | number; name: string }[];
  enableTooltip?: boolean;
  defaultOpen?: boolean;
  addorment?: {
    startAddorment?: ReactNode;
    endAddorment?: ReactNode;
  };
  variant?: 'gray' | 'default';
}

const Accordion: FC<AccordionProps> = ({
  title,
  children,
  items = [],
  enableTooltip = false,
  defaultOpen = false,
  addorment,
  variant = 'default',
}) => {
  const hasContent = useMemo(() => {
    if (children !== undefined || items.length > 0) return true;
    return false;
  }, [children, items]);
  return (
    <div className="w-full">
      <div
        className={`mx-auto w-full rounded-2xl  p-4 ${
          variant === 'default' ? 'bg-menu-light-purple' : 'bg-gray-5'
        }`}
      >
        <Disclosure defaultOpen={defaultOpen}>
          {({ open }) => (
            <div>
              <DisclosureButton
                className={`flex w-full justify-between items-center rounded-lg text-left text-base focus:outline-none ${
                  hasContent ? 'cursor-pointer' : 'cursor-default'
                }`}
              >
                <Tippy content={title} maxWidth={700} disabled={!enableTooltip}>
                  <span className="text-gray-55 font-bold truncate flex">
                    {addorment?.startAddorment}
                    {title}
                    {addorment?.endAddorment}
                  </span>
                </Tippy>
                {hasContent && (
                  <div className="text-secondary-dark">
                    {open ? <FaMinus size={12} /> : <FaPlus size={12} />}
                  </div>
                )}
              </DisclosureButton>

              {hasContent && (
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-90 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-90 opacity-0"
                >
                  <DisclosurePanel className="text-sm text-gray-50 pt-4">
                    {children}
                    {items.length > 0 && (
                      <div className="flex flex-col gap-2">
                        {items?.map((item) => (
                          <div key={item.id}>{item.name}</div>
                        ))}
                      </div>
                    )}
                  </DisclosurePanel>
                </Transition>
              )}
            </div>
          )}
        </Disclosure>
      </div>
    </div>
  );
};
export default Accordion;
