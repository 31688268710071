import styled from 'styled-components';
import tw from 'twin.macro';

interface PossibleRootCauseItemContainerProps {
  $active?: boolean;
}

export const PossibleRootCauseItemContainer = styled.div<PossibleRootCauseItemContainerProps>`
  ${tw`flex flex-col justify-center items-center px-4 py-3 w-[170px] rounded-lg text-center cursor-pointer min-h-[86px]`}
  ${(props) =>
    props.$active
      ? tw`bg-secondary text-white`
      : tw`bg-primary-lighter-2 text-secondary`}
`;
